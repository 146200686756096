import React from "react"
import NavBar from "../NavBar"
import Footer from "../Footer"
import AboutPageList from "../AboutPageList"

function knowtheirnames() {
  return (
    <>
      <div>
        <NavBar />
        <AboutPageList />
      </div>

      <div className="container mx-auto mt-4 p-4 text-gray-900 text-center break-words">
        RUMAIN BRISBON - JERAME REID - MATTHEW AJIBADE - JAMES N. POWELL JR. -
        FRANK SMART - ERNEST LACY - TONY ROBINSON - ANTHONY HILL - MYA HALL -
        PHILLIP WHITE - ERIC HARRIS - WALTER SCOTT - WILLIAM CHAPMAN II - ALEXIA
        CHRISTIAN - BRENDON GLENN - VICTOR MANUEL LAROSA - JONATHAN SANDERS -
        FREDDIE CARLOS GRAY JR. - JOSEPH MANN - SALVADO ELLSWOOD - - ALBERT
        JOSEPH DAVIS - DARRIUS STEWART - BILLY RAY DAVIS - SAMUEL DUBOSE -
        MICHAEL SABBIE - BRIAN KEITH DAY - CHRISTIAN TAYLOR - TROY ROBINSON -
        ASSHAMS PHAROAH MANLEY - MICHAEL STEWART - FELIX KUMI - KEITH HARRISON
        MCLEOD - JUNIOR PROSPER - LAMONTEZ JONES - PATERSON BROWN - DOMINIC
        HUTCHINSON - ANTHONY ASHFORD - ALONZO SMITH - TYREE CRAWFORD - INDIA
        KAGER - LA?VANTE BIGGS - MICHAEL LEE MARSHALL - JAMAR CLARK - RICHARD
        PERKINS - PHILLIP PANNELL - NATHANIEL HARRIS PICKETT - BENNI LEE TIGNOR
        - MIGUEL ESPINAL - MICHAEL NOEL - KEVIN MATTHEWS - BETTIE JONES -
        QUINTONIO LEGRIER - KEITH CHILDRESS JR. - JANET WILSON - RANDY NELSON -
        ANTRONIE SCOTT - WENDELL CELESTINE - DAVID JOSEPH - CALIN ROQUEMORE -
        DYZHAWN PERKINS - CHRISTOPHER DAVIS - MARCO LOUD - JAMES BYRD JR. -
        PETER GAINES - TORREY ROBINSON - DARIUS ROBINSON - KEVIN HICKS - MARY
        TRUXILLO - DEMARCUS SEMER - AMADOU DIALLO - WILLIE TILLMAN - TERRILL
        THOMAS - DEMETRIUS DUBOSE - ALTON STERLING - PHILANDO CASTILE - TERENCE
        CRUTCHER - PAUL O?NEAL - ALTERIA WOODS - BOBBY RUSS - JORDAN EDWARDS -
        AARON BAILEY - RONELL FOSTER - STEPHON CLARK - COREY CARTER - ANTWON
        ROSE II - TAYLER ROCK - MALICE GREEN - RAMARLEY GRAHAM - ELIJAH MCCLAIN
        - BOTHAM JEAN - PAMELA TURNER - DOMINIQUE CLAYTON - SEAN BELL - ATATIANA
        JEFFERSON - JEMEL ROBERSON - JAMES LEE ALEXANDER - RYAN MATTHEW SMITH -
        DERRICK AMBROSE JR. - ADDIE MAE COLLINS - CAROL DENISE MCNAIR - CAROLE
        ROBERTSON - CYNTHIA WESLEY - NICHOLAS HEYWARD JR. - CHRISTOPHER
        WHITFIELD - WILLIE MCCOY - VICTOR WHITE III - MARCUS DEON SMITH - CHAVIS
        CARTER - MARTIN LEE ANDERSON - CHRISTOPHER MCCORVEY - BRADLEY BLACKSHIRE
        - TIMOTHY THOMAS - DANROY "DJ" HENRY JR. - KARVAS GAMBLE JR. - ERIC
        REASON - KORRYN GAINES - REKIA BOYD - KIONTE SPENCER - DARIUS TARVER -
        WAYNE ARNOLD JONES - MANUEL ELLIS - VICTOR DUFFY JR. - KOBE
        DIMOCK-HEISLER - CLINTON R. ALLEN - DONTRE HAMILTON - TIMOTHY CAUGHMAN
      </div>
      {/* <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mx-auto container ">



</div> */}

      <Footer />
    </>
  )
}

export default knowtheirnames
